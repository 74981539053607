<template>
  <div class="invoice-concepts rounded border py-4">
    <div class="d-flex justify-content-between mb-4 px-4">
      <div class="h5">Conceptos</div>
      <div class="d-flex justify-content-end">
        <z-button @click="addConcept">
          <div class="text-decoration-none">
            <font-awesome-icon icon="plus-circle" class="mr-1" />
            Añadir concepto
          </div>
        </z-button>
      </div>
    </div>

    <div class="concepts-wrapper border-top border-bottom p-4">
      <div v-for="(concept, index) in concepts" :key="`concept-${index}`">
        <invoice-concept
          :index="index"
          :concept="concept"
          :is-retention-checked="concept.withholdingTax != null"
          @concept-saved="saveConcept"
          @concept-deleted="removeConcept"
          @concept-edited="notifyConceptUpdate"
        />
      </div>
    </div>

    <invoice-amount
      :iva.sync="invoiceAmount.iva"
      :total.sync="invoiceAmount.total"
      :retention.sync="invoiceAmount.retention"
      :subtotal.sync="invoiceAmount.subtotal"
      class="px-4"
    />
  </div>
</template>

<script>
import InvoiceAmount from "@/app/components/InvoiceAmount";
import InvoiceProduct from "@/constants/invoices/product";
import InvoiceUnit from "@/constants/invoices/unit";
import _cloneDeep from "lodash/cloneDeep";
import InvoiceConcept from "./InvoiceConcept";

const conceptPlaceholder = {
  id: 0,
  quantity: 0,
  unitValue: 0,
  amount: 0,
  isEditing: true
};

export default {
  name: "InvoiceConcepts",

  components: {
    InvoiceAmount,
    InvoiceConcept
  },

  props: {
    data: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      invoiceUnitOptions: InvoiceUnit.options,
      productOptions: InvoiceProduct.options,
      concepts: [],
      invoiceAmount: {
        iva: 0,
        subtotal: 0,
        retention: 0,
        total: 0
      }
    };
  },

  mounted() {
    if (this.data.length) {
      this.concepts = _cloneDeep(this.data).map(concept => {
        concept.unit = InvoiceUnit.get[concept.unitCode.value];
        concept.quantity = parseFloat(concept.quantity);
        concept.unitValue = parseFloat(concept.unitValue);
        concept.productCode = InvoiceProduct.options.find(
          el => el.value === concept.productCode
        );
        concept.unitCode = InvoiceUnit.options.find(
          el => el.value === concept.unitCode
        );
        concept.amount = concept.quantity * concept.unitValue;
        concept.isEditing = false;
        return concept;
      });
    } else {
      this.concepts.push({ ...conceptPlaceholder });
      this.$emit("concepts-change", this.concepts);
      this.notifyConceptUpdate({ index: 0, isEditing: true });
    }
  },

  methods: {
    addConcept() {
      let size = this.concepts.length;
      let lastConcept = this.concepts[size - 1];
      if (lastConcept.amount > 0) {
        this.concepts.push({ ...conceptPlaceholder });
        this.$emit("concepts-change", this.concepts);
      }
    },

    removeConcept(index) {
      if (this.concepts.length > 1) {
        let oldConcept = this.concepts[index];

        this.invoiceAmount.subtotal -= oldConcept.amount;
        this.invoiceAmount.retention -= oldConcept.withholdingTax?.amount ?? 0;

        this.concepts.splice(index, 1);

        this.$emit("concepts-change", this.concepts);
        this.$emit("total-change", this.invoiceAmount.subtotal);
      }
    },

    saveConcept({ concept, index }) {
      let oldConcept = this.concepts[index];

      this.invoiceAmount.subtotal -= oldConcept.amount;
      this.invoiceAmount.retention -= oldConcept.withholdingTax?.amount ?? 0;

      this.concepts[index] = concept;

      this.invoiceAmount.subtotal += concept.amount;
      this.invoiceAmount.retention += concept.withholdingTax?.amount ?? 0;

      this.$emit("concepts-change", this.concepts);
      this.$emit("total-change", this.invoiceAmount.subtotal);
    },

    notifyConceptUpdate({ index, isEditing }) {
      this.$emit("concept-edited", {
        index: index,
        isEditing: isEditing
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.concepts-wrapper {
  max-height: 379px;
  overflow-y: scroll;
}
</style>
