<template>
  <div class="px-3 pt-3">
    <b-row>
      <b-col>
        <b-form-group
          id="fieldset-subtotal"
          class="mb-4"
          label="Subtotal"
          label-for="input-subtotal"
          :state="veeErrors.first('subtotal') ? false : true"
          :invalid-feedback="veeErrors.first('subtotal')"
        >
          <b-form-input
            id="input-subtotal"
            v-validate="'min_value:0.01|decimal:2'"
            :value="subtotal"
            data-vv-as="Subtotal"
            name="subtotal"
            type="number"
            disabled
            min="0"
            trim
            @input="$emit('update:subtotal', parseFloat($event))"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="fieldset-iva"
          class="mb-4"
          label="Impuestos"
          label-for="input-iva"
          :state="veeErrors.first('iva') ? false : true"
          :invalid-feedback="veeErrors.first('iva')"
        >
          <b-form-input
            id="input-iva"
            v-validate="'min_value:0.01|decimal:2'"
            :value="iva"
            data-vv-as="IVA"
            name="iva"
            type="number"
            disabled
            min="0"
            trim
            @input="$emit('update:iva', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="fieldset-retention"
          class="mb-4"
          label="Retenciones"
          label-for="input-retention"
          :state="veeErrors.first('retention') ? false : true"
          :invalid-feedback="veeErrors.first('retention')"
        >
          <b-form-input
            id="input-retention"
            v-validate="'min_value:0.01|decimal:2'"
            :value="retention"
            data-vv-as="Retention"
            name="retention"
            type="number"
            disabled
            min="0"
            trim
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="fieldset-total"
          class="mb-4"
          label="Total"
          label-for="input-total"
          :state="veeErrors.first('total') ? false : true"
          :invalid-feedback="veeErrors.first('total')"
        >
          <b-form-input
            id="input-total"
            v-validate="'min_value:0.01|decimal:2'"
            :value="total"
            data-vv-as="Total"
            name="total"
            type="number"
            disabled
            min="0"
            trim
            @input="$emit('update:total', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import currency from "currency.js";

export default {
  name: "InvoiceAmount",

  props: {
    iva: {
      type: Number,
      default: 0
    },
    subtotal: {
      type: Number,
      default: 0
    },
    retention: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    }
  },

  watch: {
    subtotal: {
      immediate: true,
      handler(newVal) {
        const subtotal = currency(newVal || 0).value;
        const iva = currency(subtotal).multiply(0.16).value;
        const retention = currency(this.retention).value;
        const total = currency(iva)
          .add(subtotal)
          .subtract(retention).value;
        this.$emit("update:iva", iva);
        this.$emit("update:total", total);
      }
    },

    retention: {
      immediate: true,
      handler(newVal) {
        const subtotal = currency(this.subtotal).value;
        const iva = currency(this.subtotal).multiply(0.16).value;
        const retention = currency(newVal).value;
        const total = currency(iva)
          .add(subtotal)
          .subtract(retention).value;
        this.$emit("update:total", total);
      }
    }
  }
};
</script>
