const TAX_ISR_TEXT = "ISR";
const TAX_ISR_VALUE = 0;
const TAX_ISR_CODE = "001";

const TAX_IVA_TEXT = "IVA";
const TAX_IVA_VALUE = 1;
const TAX_IVA_CODE = "002";

const TAX_IEPS_TEXT = "IEPS";
const TAX_IEPS_VALUE = 2;
const TAX_IEPS_CODE = "003";

const TAX_TYPE_RATE_TEXT = "Tasa";
const TAX_TYPE_RATE_VALUE = 0;

const TAX_TYPE_FEE_TEXT = "Cuota";
const TAX_TYPE_FEE_VALUE = 1;

const TAX_TYPE_EXEMPT_TEXT = "Exento";
const TAX_TYPE_EXEMPT_VALUE = 2;

const taxOptions = [
  { value: TAX_ISR_VALUE, text: TAX_ISR_TEXT, code: TAX_ISR_CODE },
  { value: TAX_IVA_VALUE, text: TAX_IVA_TEXT, code: TAX_IVA_CODE },
  { value: TAX_IEPS_VALUE, text: TAX_IEPS_TEXT, code: TAX_IEPS_CODE }
];

const taxTypes = [
  { value: TAX_TYPE_RATE_VALUE, text: TAX_TYPE_RATE_TEXT },
  { value: TAX_TYPE_FEE_VALUE, text: TAX_TYPE_FEE_TEXT },
  { value: TAX_TYPE_EXEMPT_VALUE, text: TAX_TYPE_EXEMPT_TEXT }
];

export default {
  TAX_ISR_TEXT,
  TAX_ISR_VALUE,
  TAX_ISR_CODE,

  TAX_IVA_TEXT,
  TAX_IVA_VALUE,
  TAX_IVA_CODE,

  TAX_IEPS_TEXT,
  TAX_IEPS_VALUE,
  TAX_IEPS_CODE,

  TAX_TYPE_RATE_TEXT,
  TAX_TYPE_RATE_VALUE,

  TAX_TYPE_FEE_VALUE,
  TAX_TYPE_FEE_TEXT,

  TAX_TYPE_EXEMPT_VALUE,
  TAX_TYPE_EXEMPT_TEXT,

  taxOptions,
  taxTypes
};
