<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-form-group
          :id="`${index}-fieldset-concept`"
          class="mb-4"
          :label="`Concepto ${index + 1}`"
          label-for="input-concept"
          :state="veeErrors.first(`concept-${index}`) ? false : true"
          :invalid-feedback="veeErrors.first(`concept-${index}`)"
        >
          <b-form-input
            :id="`${index}-input-concept`"
            v-model="description"
            v-validate="'required'"
            :name="`concept-${index}`"
            data-vv-as="Concepto"
            trim
            :disabled="!isEditing"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="6">
        <b-form-group
          :id="`${index}-fieldset-service-unit-id`"
          class="mb-4"
          label="Clave de unidad de servicio"
          label-for="select-service-unit-id"
        >
          <z-dropdown
            :id="`${index}-select-service-unit-id`"
            v-model="unitCode"
            :options="invoiceUnitOptions"
            variant="white"
            :show-header="false"
            :disabled="!isEditing"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="6">
        <b-form-group
          :id="`${index}-fieldset-service-id`"
          class="mb-4"
          label="Clave de producto o servicio"
          label-for="select-service-id"
        >
          <z-dropdown
            :id="`${index}-select-service-id`"
            v-model="productCode"
            :options="productOptions"
            variant="white"
            :show-header="false"
            :disabled="!isEditing"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :id="`${index}-fieldset-units`"
          class="mb-4"
          label="No. unidades"
          label-for="input-units"
          :state="veeErrors.first(`units-${index}`) ? false : true"
          :invalid-feedback="veeErrors.first(`units-${index}`)"
        >
          <b-form-input
            :id="`${index}-input-units`"
            v-model="quantity"
            v-validate="'min_value:1|decimal:0'"
            :name="`units-${index}`"
            data-vv-as="No. unidades"
            placeholder="1"
            type="number"
            min="1"
            trim
            :disabled="!isEditing"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :id="`${index}-fieldset-unit-price`"
          class="mb-4"
          label="Precio unitario"
          label-for="input-unit-price"
          :state="veeErrors.first(`unit-price-${index}`) ? false : true"
          :invalid-feedback="veeErrors.first(`unit-price-${index}`)"
        >
          <b-form-input
            :id="`${index}-input-unit-price`"
            v-model="unitValue"
            v-validate="'min_value:0.01|decimal:2'"
            :name="`unit-price-${index}`"
            data-vv-as="Precio unitario"
            placeholder="$ 1.00"
            type="number"
            min="1"
            trim
            :disabled="!isEditing"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :id="`${index}-fieldset-import`"
          class="mb-4"
          label="Importe"
          label-for="input-import"
        >
          <b-form-input
            :id="`${index}-input-import`"
            :value="conceptAmount"
            disabled
            name="unit-price"
            placeholder="$ 0.00"
            type="number"
            min="0"
            trim
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- RETENTION -->
    <b-row>
      <b-col>
        <b-form-group :id="`${index}-fieldset-retention`">
          <b-form-checkbox
            :id="`${index}-checkbox-retention`"
            v-model="retentionChecked"
            :disabled="!isEditing"
          >
            Retenciones
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="retentionChecked">
      <b-col>
        <div class="mb-2 font-weight-semi-bold">Retención</div>
      </b-col>
    </b-row>
    <b-row v-if="retentionChecked">
      <b-col>
        <b-form-group
          :id="`${index}-fieldset-base`"
          class="mb-4"
          label="Base*:"
          label-for="input-base"
        >
          <b-form-input
            :id="`${index}-input-base`"
            name="unit-price"
            placeholder="$ 0.00"
            :value="conceptAmount"
            disabled
            type="number"
            min="0"
            trim
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :id="`${index}-fieldset-tax`"
          class="mb-4"
          label="Impuesto*:"
          label-for="input-tax"
        >
          <z-dropdown
            :id="`${index}-select-tax`"
            v-model="retention.tax"
            :options="retentionTaxOptions"
            variant="white"
            :show-header="false"
            :disabled="!isEditing"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :id="`${index}-fieldset-share`"
          class="mb-4"
          label="¿Tasa o cuota?*:"
          label-for="input-share"
        >
          <z-dropdown
            :id="`${index}-select-share`"
            v-model="retention.taxType"
            :options="retentionTaxTypes"
            variant="white"
            :show-header="false"
            :disabled="!isEditing"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="retentionChecked">
      <b-col>
        <b-form-group
          :id="`${index}-fieldset-share-value`"
          class="mb-4"
          label="Valor de la tasa o cuota*:"
          label-for="input-share-value"
        >
          <b-form-input
            :id="`${index}-input-share-value`"
            v-model="retention.taxRate"
            name="unit-price"
            placeholder="0.0"
            type="number"
            min="0"
            max="1"
            :formatter="taxRateFormatter"
            trim
            :disabled="!isEditing"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :id="`${index}-fieldset-retention-import`"
          class="mb-4"
          label="Importe*:"
          label-for="input-retention-import"
        >
          <b-form-input
            :id="`${index}-input-retention-import`"
            name="unit-price"
            placeholder="$ 0.00"
            type="number"
            min="0"
            disabled
            :value="retentionAmount"
            trim
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- SUBMIT ACTIONS -->
    <div class="d-flex justify-content-end">
      <z-button
        class="mr-3"
        :disabled="!isValid"
        @click="dispatchUpdateButton(index)"
      >
        {{ isEditing ? "Guardar" : "Editar" }}
      </z-button>
      <z-button
        :disabled="index === 0"
        variant="secondary"
        @click="deleteConcept(index)"
      >
        Eliminar
      </z-button>
    </div>
    <hr />
  </div>
</template>

<script>
import InvoiceProduct from "@/constants/invoices/product";
import InvoiceUnit from "@/constants/invoices/unit";
import RetentionOptions from "@/constants/invoices/retention";
import currency from "currency.js";
import validateStateMixin from "@/mixins/validateState";
import ZDropdown from "@zubut/common/src/components/ZDropdown";

export default {
  name: "InvoiceConcept",

  components: {
    ZDropdown
  },

  mixins: [validateStateMixin],

  props: {
    index: {
      type: Number,
      default: 0
    },
    concept: {
      type: Object,
      default: null
    },
    isRetentionChecked: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isEditing: this.concept?.isEditing ?? true,

      invoiceUnitOptions: InvoiceUnit.options,
      productOptions: InvoiceProduct.options,
      retentionTaxOptions: RetentionOptions.taxOptions,
      retentionTaxTypes: RetentionOptions.taxTypes,

      quantity: this.concept?.quantity ?? 0,
      unit: this.concept?.unit ?? InvoiceUnit.SERVICE,
      unitCode: this.concept?.unitCode ?? InvoiceUnit.SERVICE_KEY,
      unitValue: this.concept?.unitValue ?? 0,
      amount: this.concept?.amount ?? 0,
      productCode:
        this.concept?.productCode ?? InvoiceProduct.SMALL_PACKAGE_DELIVERY_KEY,
      description: this.concept?.description ?? "Servicio de Mensajería Zubut",
      retentionChecked: this.isRetentionChecked,
      retention: {
        base: this.concept?.withholdingTax?.base ?? this.concept?.amount ?? 0,
        tax: RetentionOptions.taxOptions.find(
          el =>
            el.code === this.concept?.withholdingTax?.taxCode ||
            el.code === RetentionOptions.TAX_IVA_CODE
        ).value,
        taxType: RetentionOptions.taxTypes.find(
          el =>
            el.text === this.concept?.withholdingTax?.taxType ||
            el.text === RetentionOptions.TAX_TYPE_RATE_TEXT
        ).value,
        taxRate: this.concept?.withholdingTax?.taxRate ?? 0.06,
        amount: currency(this.concept.quantity ?? 0)
          .multiply(this.concept?.unitValue ?? 0)
          .multiply(this.concept?.withholdingTax?.taxRate ?? 0.06)
      }
    };
  },

  computed: {
    isValid() {
      return this.isFormValid;
    },

    conceptAmount() {
      return currency(this.quantity).multiply(this.unitValue).value;
    },

    retentionAmount() {
      return currency(this.conceptAmount).multiply(this.retention.taxRate)
        .value;
    }
  },

  watch: {
    isValid(newVal) {
      this.$emit("valid", newVal);
    },

    concept(value) {
      this.isEditing = value.isEditing;
      this.quantity = value.quantity ?? 0;
      this.unit = value.unit ?? InvoiceUnit.SERVICE;
      this.unitCode = InvoiceUnit.options.find(
        el =>
          el.value === value.unitCode || el.value === InvoiceUnit.SERVICE_KEY
      ).value;
      this.unitValue = value.unitValue ?? 0;
      this.amount = value.amount ?? 0;
      this.productCode = InvoiceProduct.options.find(
        el =>
          el.value === value.productCode ||
          el.value === InvoiceProduct.SMALL_PACKAGE_DELIVERY_KEY
      ).value;
      this.description = value.description ?? "Servicio de Mensajería Zubut";
      this.retentionChecked = this.isRetentionChecked;
      this.retention = {
        base: value.withholdingTax?.base ?? value?.amount ?? 0,
        tax: RetentionOptions.taxOptions.find(
          el =>
            el.code === value.withholdingTax?.taxCode ||
            el.code === RetentionOptions.TAX_IVA_CODE
        ).value,
        taxType: RetentionOptions.taxTypes.find(
          el =>
            el.text === value.withholdingTax?.taxType ||
            el.text === RetentionOptions.TAX_TYPE_RATE_TEXT
        ).value,
        taxRate: value.withholdingTax?.taxRate ?? 0.06,
        amount: currency(value.quantity ?? 0)
          .multiply(value.unitValue ?? 0)
          .multiply(value.withholdingTax?.taxRate ?? 0.06)
      };
    }
  },

  methods: {
    dispatchUpdateButton(index) {
      if (this.conceptAmount > 0) {
        if (this.isEditing) {
          this.isEditing = false;
          this.saveConcept(index);
        } else {
          this.isEditing = true;
        }
        this.$emit("concept-edited", {
          index: index,
          isEditing: this.isEditing
        });
      }
    },

    saveConcept(index) {
      let concept = {
        quantity: parseFloat(this.quantity),
        unit: this.unit,
        unitCode: this.unitCode.value,
        unitValue: parseFloat(this.unitValue),
        amount: parseFloat(this.conceptAmount),
        productCode: this.productCode.value,
        description: this.description
      };

      if (this.retentionChecked) {
        let withholdingTax = {
          base: parseFloat(this.conceptAmount),
          taxCode: this.retention.tax.code,
          taxType: this.retention.taxType.text,
          taxRate: String(parseFloat(this.retention.taxRate)),
          amount: parseFloat(this.retentionAmount)
        };
        concept.withholdingTax = withholdingTax;
      }

      this.$emit("concept-saved", { concept: concept, index: index });
    },

    deleteConcept(index) {
      this.$emit("concept-deleted", index);
    },

    taxRateFormatter(value) {
      if (value > 1) return 1;
      return value;
    }
  }
};
</script>
