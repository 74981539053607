<template>
  <z-dropdown-clients
    :value="value"
    :name="value ? '' : 'Selecciona el cliente'"
    :first-option="firstOption"
    variant="white"
    search-placeholder="Buscar cliente"
    @input="handleInput"
  />
</template>

<script>
import Clients from "@/services/clients";
import ZDropdownClients from "@/app/components/ZDropdownClients";

export default {
  name: "ZDropdownReceivers",

  components: {
    ZDropdownClients
  },

  props: {
    value: {
      type: [Number, String],
      default: null
    }
  },

  data() {
    return {
      loading: false,
      firstOption: { text: "Nuevo cliente", value: null }
    };
  },

  methods: {
    handleInput(clientId) {
      this.$emit("input", clientId);

      if (!clientId) {
        this.$emit("change", clientId);
        return;
      }

      this.loading = true;
      Clients.findById({
        id: clientId,
        filter: {
          include: [
            "billingData",
            { relation: "account", scope: { include: ["billingData"] } }
          ]
        }
      })
        .then(res => {
          this.$emit("change", res);
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
