export default {
  computed: {
    isFormChanged() {
      const changedFields = Object.keys(this.veeFields).some(
        key => this.veeFields[key].changed === true
      );
      return changedFields;
    },
    isFormDirty() {
      const changedFields = Object.keys(this.veeFields).some(
        key => this.veeFields[key].dirty
      );
      return changedFields;
    },
    isFormPristine() {
      const dirtyFields = Object.keys(this.veeFields).some(
        key => this.veeFields[key].pristine === false
      );
      return !dirtyFields;
    },
    isFormValid() {
      const invalidFields = Object.keys(this.veeFields).some(
        key => this.veeFields[key].valid === false
      );
      return !invalidFields;
    }
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    isValidField(fieldName) {
      if (!this.veeFields[fieldName] || !this.veeFields[fieldName].dirty) {
        return null;
      }
      return this.veeFields[fieldName].valid;
    }
  }
};
