<template>
  <div class="receiver-form border rounded p-4">
    <div class="h5 mb-4">
      Receptor
    </div>
    <b-row>
      <b-col>
        <b-form-group
          id="fieldset-client"
          class="mb-4"
          label="Cliente"
          label-for="select-client"
        >
          <z-dropdown-receiver
            id="select-client"
            v-model="receiverForm.user.id"
            @change="handleClientChange"
          ></z-dropdown-receiver>
        </b-form-group>
        <b-form-group
          id="fieldset-social-reason"
          class="mb-4"
          label="Razón social"
          label-for="input-social-reason"
          :state="veeErrors.first('legal-name') ? false : true"
          :invalid-feedback="veeErrors.first('legal-name')"
        >
          <b-form-input
            id="input-social-reason"
            v-model="receiverForm.billingName"
            v-validate="'required'"
            name="legal-name"
            data-vv-as="Razón social"
            trim
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="fieldset-rfc"
          class="mb-4"
          label="RFC"
          label-for="input-rfc"
          :state="veeErrors.first('rfc') ? false : true"
          :invalid-feedback="veeErrors.first('rfc')"
        >
          <b-form-input
            id="input-rfc"
            v-model="receiverForm.rfc"
            v-validate="'required'"
            name="rfc"
            data-vv-as="RFC"
            trim
          ></b-form-input>
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group
              id="fieldset-email"
              class="mb-4"
              label="Correo electrónico"
              label-for="input-email"
            >
              <b-form-input
                id="input-email"
                v-model="receiverForm.email"
                type="email"
                trim
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="fieldset-phone"
              class="mb-4"
              label="Teléfono"
              label-for="input-phone"
            >
              <b-form-input
                id="input-phone"
                v-model="receiverForm.phone"
                trim
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-row>
          <b-col>
            <b-form-group
              id="fieldset-zip-code"
              class="mb-4"
              label="Código postal"
              label-for="input-zip-code"
            >
              <b-form-input
                id="input-zip-code"
                v-model="receiverForm.zipCode"
                trim
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="fieldset-state"
              class="mb-4"
              label="Estado"
              label-for="input-state"
            >
              <b-form-input
                id="input-state"
                v-model="receiverForm.state"
                trim
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              id="fieldset-city"
              class="mb-4"
              label="Ciudad"
              label-for="input-city"
            >
              <b-form-input
                id="input-city"
                v-model="receiverForm.city"
                trim
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="fieldset-neighborhood"
              class="mb-4"
              label="Colonia"
              label-for="input-neighborhood"
            >
              <b-form-input
                id="input-neighborhood"
                v-model="receiverForm.neighborhood"
                trim
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group
          id="fieldset-street"
          class="mb-4"
          label="Calle"
          label-for="input-street"
        >
          <b-form-input
            id="input-street"
            v-model="receiverForm.street"
            trim
          ></b-form-input>
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group
              id="fieldset-number-interior"
              class="mb-4"
              label="No. interior"
              label-for="input-number-interior"
            >
              <b-form-input
                id="input-number-interior"
                v-model="receiverForm.numInt"
                trim
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="fieldset-number-exterior"
              class="mb-4"
              label="No. exterior"
              label-for="input-number-exterior"
            >
              <b-form-input
                id="input-number-exterior"
                v-model="receiverForm.numExt"
                trim
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-checkbox v-model="receiverForm.useAlternateEmails">
          Enviar factura a las cuentas de correo alternas de este usuario.
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ZDropdownReceiver from "./ZDropdownReceiver";
import validateStateMixin from "@/mixins/validateState";

export default {
  name: "ReceiverForm",

  components: {
    ZDropdownReceiver
  },

  mixins: [validateStateMixin],

  props: {
    data: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      receiverForm: {
        user: { id: null },
        name: "",
        billingName: "",
        rfc: "",
        email: "",
        phone: "",
        zipCode: "",
        state: "",
        city: "",
        neighborhood: "",
        street: "",
        numInt: "",
        numExt: "",
        useAlternateEmails: false
      },
      isCreatingInvoice: false
    };
  },

  computed: {
    isValidReceiverAccount() {
      return this.receiverForm.user != null;
    },
    isValidReceiverForm() {
      return this.isFormValid;
    },
    receiverBillingUsers() {
      return this.billingUsers.concat(this.billingAccounts);
    },
    billingAccounts() {
      return this.$store.state.invoices.billingAccounts;
    },
    billingUsers() {
      return [{ id: null, name: "Nuevo cliente" }].concat(
        this.$store.state.invoices.billingUsers
      );
    }
  },

  watch: {
    isValidReceiverForm(newVal) {
      this.$emit("valid", newVal);
    },

    receiverForm: {
      deep: true,
      handler(newVal) {
        this.$emit("form-change", newVal);

        if (this.hasBilligDataChanges(newVal.user)) {
          this.$emit("billing-data-change", true);
        }
      }
    }
  },

  beforeMount() {
    if (this.data) {
      this.receiverForm.user = this.data.user;
      this.receiverForm.name = this.data.name;
      this.receiverForm.billingName = this.data.billingName;
      this.receiverForm.rfc = this.data.rfc;
      this.receiverForm.email = this.data.email;
      this.receiverForm.phone = this.data.phone;
      this.receiverForm.zipCode = this.data.zipCode;
      this.receiverForm.state = this.data.state;
      this.receiverForm.city = this.data.city;
      this.receiverForm.neighborhood = this.data.neighborhood;
      this.receiverForm.street = this.data.street;
      this.receiverForm.numInt = this.data.numInt;
      this.receiverForm.numExt = this.data.numExt;
    }
  },

  methods: {
    clearReceiverFormFields() {
      this.receiverForm.name = "";
      this.receiverForm.billingName = "";
      this.receiverForm.rfc = "";
      this.receiverForm.email = "";
      this.receiverForm.phone = "";
      this.receiverForm.zipCode = "";
      this.receiverForm.state = "";
      this.receiverForm.city = "";
      this.receiverForm.neighborhood = "";
      this.receiverForm.street = "";
      this.receiverForm.numInt = "";
      this.receiverForm.numExt = "";
      this.receiverForm.useAlternateEmails = false;
    },

    hasBilligDataChanges(user) {
      if (user != null && user.id != null && user.billingData != null) {
        const { email, phone } = user;
        const {
          billingName,
          city,
          neighborhood,
          numExt,
          numInt,
          rfc,
          state,
          street,
          zipcode
        } = user.billingData;

        if (this.receiverForm.billingName !== billingName) {
          return true;
        }

        if (this.receiverForm.city !== city) {
          return true;
        }

        if (this.receiverForm.neighborhood !== neighborhood) {
          return true;
        }

        if (this.receiverForm.numExt !== numExt) {
          return true;
        }

        if (this.receiverForm.numInt !== numInt) {
          return true;
        }

        if (this.receiverForm.rfc !== rfc) {
          return true;
        }

        if (this.receiverForm.state !== state) {
          return true;
        }

        if (this.receiverForm.street !== street) {
          return true;
        }

        if (this.receiverForm.zipCode !== zipcode) {
          return true;
        }

        if (this.receiverForm.email !== email) {
          return true;
        }
        if (this.receiverForm.phone !== phone) {
          return true;
        }
      }

      return false;
    },

    handleClientChange(client) {
      this.$emit("billing-data-change", false);
      if (client != null && client.id != null && client.billingData != null) {
        const { email, phone } = client;
        const {
          billingName,
          city,
          neighborhood,
          numExt,
          numInt,
          rfc,
          state,
          street,
          zipcode
        } = client.billingData;
        this.receiverForm.billingName = billingName;
        this.receiverForm.city = city;
        this.receiverForm.neighborhood = neighborhood;
        this.receiverForm.numExt = numExt;
        this.receiverForm.numInt = numInt;
        this.receiverForm.rfc = rfc;
        this.receiverForm.state = state;
        this.receiverForm.street = street;
        this.receiverForm.zipCode = zipcode;
        this.receiverForm.email = email;
        this.receiverForm.phone = phone ? phone : client.contactPhone;
        this.receiverForm.useAlternateEmails = false;
      } else {
        this.clearReceiverFormFields();
      }
    }
  }
};
</script>

<style lang="scss">
.receiver-form #select-client {
  .v-dropdown-item:nth-child(2) {
    color: #0177fd;
    background-color: #3d60d90f;
    margin: 0.4em;
    border-bottom: 1px solid #e0e0e0;

    &:hover {
      color: #0177fd !important;
    }
  }
}
</style>
