// Keys
const GENERAL_EXPENSE_KEY = "G03";
const TO_DEFINE_KEY = "P01";

// Key name
const GENERAL_EXPENSE = "Gastos en General";
const TO_DEFINE = "Por definir";

const get = {};
get[GENERAL_EXPENSE_KEY] = GENERAL_EXPENSE;

const options = [
  {
    value: GENERAL_EXPENSE_KEY,
    text: `${GENERAL_EXPENSE_KEY} - ${GENERAL_EXPENSE}`
  },
  {
    value: TO_DEFINE_KEY,
    text: `${TO_DEFINE_KEY} - ${TO_DEFINE}`
  }
];

export default {
  get,
  options,
  GENERAL_EXPENSE_KEY,
  GENERAL_EXPENSE,
  TO_DEFINE_KEY,
  TO_DEFINE
};
