// Keys
const SERVICE_KEY = "E48";

// Key name
const SERVICE = "Unidad de Servicio";

const get = {};
get[SERVICE_KEY] = SERVICE;

const options = [{ value: SERVICE_KEY, text: `${SERVICE_KEY} - ${SERVICE}` }];

export default {
  get,
  options,
  SERVICE_KEY,
  SERVICE
};
