// Keys
const ONE_TIME_PAYMENT_KEY = "PUE";
const PARTIAL_PAYMENT_KEY = "PPD";

// Key name
const ONE_TIME_PAYMENT = "Pago en una sola exhibición";
const PARTIAL_PAYMENT = "Pago en parcialidades o diferido ";

const get = {};
get[ONE_TIME_PAYMENT_KEY] = ONE_TIME_PAYMENT;

const options = [
  {
    value: ONE_TIME_PAYMENT_KEY,
    text: `${ONE_TIME_PAYMENT_KEY} - ${ONE_TIME_PAYMENT}`
  },
  {
    value: PARTIAL_PAYMENT_KEY,
    text: `${PARTIAL_PAYMENT_KEY} - ${PARTIAL_PAYMENT}`
  }
];

export default {
  get,
  options,
  ONE_TIME_PAYMENT_KEY,
  ONE_TIME_PAYMENT,
  PARTIAL_PAYMENT_KEY,
  PARTIAL_PAYMENT
};
