import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("BillingData");

let BillingData = Object.assign(
  {},
  {
    relation: "",

    createBillingData(data) {
      return loopback.post(`/${this.model}`, data);
    },

    updateBillingData(data) {
      return loopback.put(`/${this.model}`, data);
    },

    getBillingData({ where = {} }) {
      return loopback.get(`/${this.model}`, {
        params: { filter: { where } }
      });
    }
  },
  modelObject
);

export default BillingData;
