// Keys
const CASH_KEY = "01";
const NOMINAL_CHECK_KEY = "02";
const TRANSFER_KEY = "03";
const CREDIT_CARD_KEY = "04";
const DIGITAL_WALLET_KEY = "05";
const DIGITAL_MONEY_KEY = "06";
const PANTRY_VOUCHERS_KEY = "08";
const DATION_PAYMENT_KEY = "12";
const SUBROGATION_PAYMENT_KEY = "13";
const CONSIGNMENT_PAYMENT_KEY = "14";
const CONDONATION_KEY = "15";
const COMPENSATION_KEY = "17";
const NOVATION_KEY = "23";
const CONFUSION_KEY = "24";
const DEBT_REMITTANCE_KEY = "25";
const EXPIRATION_DESCRIPTION_KEY = "26";
const CREDITOR_SATISFACTION_KEY = "27";
const DEBIT_CARD_KEY = "28";
const SERVICE_CARD_KEY = "29";
const ADVANCED_PAYMENTS_KEY = "30";
const PAYMENT_INTERMEDIARY_KEY = "31";
const TO_DEFINE_KEY = "99";

// Key name
const CASH = "Efectivo";
const NOMINAL_CHECK = "Cheque nominativo";
const TRANSFER = "Transferencia electrónica de fondos";
const CREDIT_CARD = "Tarjeta de crédito";
const DIGITAL_WALLET = "Monedero electrónico";
const DIGITAL_MONEY = "Dinero electrónico";
const PANTRY_VOUCHERS = "Vales de despensa";
const DATION_PAYMENT = "Dación en pago";
const SUBROGATION_PAYMENT = "Pago por subrogación";
const CONSIGNMENT_PAYMENT = "Pago por consignación";
const CONDONATION = "Condonación";
const COMPENSATION = "Compensación";
const NOVATION = "Novación";
const CONFUSION = "Confusión";
const DEBT_REMITTANCE = "Remisión de deuda";
const EXPIRATION_DESCRIPTION = "Prescripción o caducidad";
const CREDITOR_SATISFACTION = "A satisfacción del acreedor";
const DEBIT_CARD = "Tarjeta de débito";
const SERVICE_CARD = "Tarjeta de servicios";
const ADVANCED_PAYMENTS = "Aplicación de anticipos";
const PAYMENT_INTERMEDIARY = "Intermediario pagos";
const TO_DEFINE = "Por definir";

const get = {};
get[CASH_KEY] = CASH;
get[NOMINAL_CHECK_KEY] = NOMINAL_CHECK;
get[TRANSFER_KEY] = TRANSFER;
get[CREDIT_CARD_KEY] = CREDIT_CARD;
get[DIGITAL_WALLET_KEY] = DIGITAL_WALLET;
get[DIGITAL_MONEY_KEY] = DIGITAL_MONEY;
get[PANTRY_VOUCHERS_KEY] = PANTRY_VOUCHERS;
get[DATION_PAYMENT_KEY] = DATION_PAYMENT;
get[SUBROGATION_PAYMENT_KEY] = SUBROGATION_PAYMENT;
get[CONSIGNMENT_PAYMENT_KEY] = CONSIGNMENT_PAYMENT;
get[CONDONATION_KEY] = CONDONATION;
get[COMPENSATION_KEY] = COMPENSATION;
get[NOVATION_KEY] = NOVATION;
get[CONFUSION_KEY] = CONFUSION;
get[DEBT_REMITTANCE_KEY] = DEBT_REMITTANCE;
get[EXPIRATION_DESCRIPTION_KEY] = EXPIRATION_DESCRIPTION;
get[CREDITOR_SATISFACTION_KEY] = CREDITOR_SATISFACTION;
get[DEBIT_CARD_KEY] = DEBIT_CARD;
get[SERVICE_CARD_KEY] = SERVICE_CARD;
get[ADVANCED_PAYMENTS_KEY] = ADVANCED_PAYMENTS;
get[PAYMENT_INTERMEDIARY_KEY] = PAYMENT_INTERMEDIARY;
get[TO_DEFINE_KEY] = TO_DEFINE;

const options = [
  { value: CASH_KEY, text: `${CASH_KEY} - ${CASH}` },
  { value: NOMINAL_CHECK_KEY, text: `${NOMINAL_CHECK_KEY} - ${NOMINAL_CHECK}` },
  { value: TRANSFER_KEY, text: `${TRANSFER_KEY} - ${TRANSFER}` },
  { value: CREDIT_CARD_KEY, text: `${CREDIT_CARD_KEY} - ${CREDIT_CARD}` },
  {
    value: DIGITAL_WALLET_KEY,
    text: `${DIGITAL_WALLET_KEY} - ${DIGITAL_WALLET}`
  },
  { value: DIGITAL_MONEY_KEY, text: `${DIGITAL_MONEY_KEY} - ${DIGITAL_MONEY}` },
  {
    value: PANTRY_VOUCHERS_KEY,
    text: `${PANTRY_VOUCHERS_KEY} - ${PANTRY_VOUCHERS}`
  },
  {
    value: DATION_PAYMENT_KEY,
    text: `${DATION_PAYMENT_KEY} - ${DATION_PAYMENT}`
  },
  {
    value: SUBROGATION_PAYMENT_KEY,
    text: `${SUBROGATION_PAYMENT_KEY} - ${SUBROGATION_PAYMENT}`
  },
  {
    value: CONSIGNMENT_PAYMENT_KEY,
    text: `${CONSIGNMENT_PAYMENT_KEY} - ${CONSIGNMENT_PAYMENT}`
  },
  { value: CONDONATION_KEY, text: `${CONDONATION_KEY} - ${CONDONATION}` },
  { value: COMPENSATION_KEY, text: `${COMPENSATION_KEY} - ${COMPENSATION}` },
  { value: NOVATION_KEY, text: `${NOVATION_KEY} - ${NOVATION}` },
  { value: CONFUSION_KEY, text: `${CONFUSION_KEY} - ${CONFUSION}` },
  {
    value: DEBT_REMITTANCE_KEY,
    text: `${DEBT_REMITTANCE_KEY} - ${DEBT_REMITTANCE}`
  },
  {
    value: EXPIRATION_DESCRIPTION_KEY,
    text: `${EXPIRATION_DESCRIPTION_KEY} - ${EXPIRATION_DESCRIPTION}`
  },
  {
    value: CREDITOR_SATISFACTION_KEY,
    text: `${CREDITOR_SATISFACTION_KEY} - ${CREDITOR_SATISFACTION}`
  },
  { value: DEBIT_CARD_KEY, text: `${DEBIT_CARD_KEY} - ${DEBIT_CARD}` },
  { value: SERVICE_CARD_KEY, text: `${SERVICE_CARD_KEY} - ${SERVICE_CARD}` },
  {
    value: ADVANCED_PAYMENTS_KEY,
    text: `${ADVANCED_PAYMENTS_KEY} - ${ADVANCED_PAYMENTS}`
  },
  {
    value: PAYMENT_INTERMEDIARY_KEY,
    text: `${PAYMENT_INTERMEDIARY_KEY} - ${PAYMENT_INTERMEDIARY}`
  },
  { value: TO_DEFINE_KEY, text: `${TO_DEFINE_KEY} - ${TO_DEFINE}` }
];

export default {
  get,
  options,
  CASH_KEY,
  CASH,
  NOMINAL_CHECK_KEY,
  NOMINAL_CHECK,
  TRANSFER_KEY,
  TRANSFER,
  CREDIT_CARD_KEY,
  CREDIT_CARD,
  DIGITAL_WALLET_KEY,
  DIGITAL_WALLET,
  DIGITAL_MONEY_KEY,
  DIGITAL_MONEY,
  PANTRY_VOUCHERS_KEY,
  PANTRY_VOUCHERS,
  DATION_PAYMENT_KEY,
  DATION_PAYMENT,
  SUBROGATION_PAYMENT_KEY,
  SUBROGATION_PAYMENT,
  CONSIGNMENT_PAYMENT_KEY,
  CONSIGNMENT_PAYMENT,
  CONDONATION_KEY,
  CONDONATION,
  COMPENSATION_KEY,
  COMPENSATION,
  NOVATION_KEY,
  NOVATION,
  CONFUSION_KEY,
  CONFUSION,
  DEBT_REMITTANCE_KEY,
  DEBT_REMITTANCE,
  EXPIRATION_DESCRIPTION_KEY,
  EXPIRATION_DESCRIPTION,
  CREDITOR_SATISFACTION_KEY,
  CREDITOR_SATISFACTION,
  DEBIT_CARD_KEY,
  DEBIT_CARD,
  SERVICE_CARD_KEY,
  SERVICE_CARD,
  ADVANCED_PAYMENTS_KEY,
  ADVANCED_PAYMENTS,
  PAYMENT_INTERMEDIARY_KEY,
  PAYMENT_INTERMEDIARY,
  TO_DEFINE_KEY,
  TO_DEFINE
};
