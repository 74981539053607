<template>
  <z-dropdown
    search
    :options="clientsOptions"
    :loading="loadingClients"
    :max-height="maxHeight"
    :name="name"
    :value="value"
    :variant="variant"
    :placeholder="placeholder"
    :search-placeholder="searchPlaceholder"
    v-bind="$attrs"
    @input="$emit('input', $event)"
    @search-change="searchClient"
  />
</template>

<script>
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ClientTypes from "@zubut/common/src/constants/clients/type";
import Clients from "@/services/clients";
import _debounce from "lodash/debounce";

export default {
  name: "ZDropdownClients",

  components: {
    ZDropdown
  },

  props: {
    clientTypes: {
      type: [Array, Number],
      default: () => [
        ClientTypes.NUM_CLIENT,
        ClientTypes.NUM_COMPANY,
        ClientTypes.NUM_BRANCH
      ]
    },
    firstOption: {
      type: [Object, Boolean],
      default: () => ({ text: "Todos", value: null })
    },
    maxHeight: {
      type: String,
      default: "400px"
    },
    name: {
      type: String,
      default: "Cliente"
    },
    placeholder: {
      type: String,
      default: ""
    },
    searchPlaceholder: {
      type: String,
      default: "Buscar cliente"
    },
    value: {
      type: [Number, String],
      default: null
    },
    variant: {
      type: String,
      default: "default",
      validator: val => ["default", "white"].indexOf(val) > -1
    },
    exclude: {
      type: Array,
      default: null
    }
  },

  data() {
    return {
      loadingClients: true,
      clientsOptions: this.firstOption ? [this.firstOption] : []
    };
  },

  computed: {
    hasCurrentOption() {
      return this.clientsOptions.find(op => this.value === op?.value);
    },
    selectedCity() {
      return this.$store.getters["cities/getCity"];
    }
  },

  watch: {
    value() {
      if (!this.hasCurrentOption) {
        this.getClientsOptions();
      }
    },
    selectedCity() {
      this.getClientsOptions();
    }
  },

  created() {
    this.searchClient = _debounce(searchText => {
      this.getClientsOptions(searchText);
    }, 600);
    if (!this.clientsOptions.length) {
      this.getClientsOptions();
    }
  },

  methods: {
    getClientsOptions(searchText = "") {
      this.loadingClients = true;
      let where = {
        like: searchText,
        type: this.clientTypes
      };
      if (this.selectedCity) {
        where.cityId = this.selectedCity;
      }
      if (!searchText && !this.hasCurrentOption) {
        searchText = this.value;
      }
      if (this.exclude != null) {
        where = { ...where, id: { nin: this.exclude } };
      }
      searchText = searchText ? searchText.toLowerCase() : searchText;
      if (
        searchText === "" &&
        this.$store.getters["clients/clientsFilterOptions"].length > 1
      ) {
        this.clientsOptions = this.$store.getters[
          "clients/clientsFilterOptions"
        ];
        this.loadingClients = false;
      } else {
        if (searchText === "") {
          this.$store
            .dispatch("clients/getClientsFilter", where)
            .then(_ => {
              this.clientsOptions = this.$store.getters[
                "clients/clientsFilterOptions"
              ];
            })
            .finally(() => {
              this.loadingClients = false;
            });
        } else {
          Clients.nameListing({
            filter: { skip: 0, limit: 5, order: "lastLogin DESC" },
            where
          })
            .then(res => {
              if (this.firstOption) {
                this.clientsOptions = [this.firstOption].concat(
                  res.data.map(user => ({
                    value: user.id,
                    text: user.name
                  }))
                );
              } else {
                this.clientsOptions = res.data.map(user => ({
                  value: user.id,
                  text: user.name
                }));
              }

              if (!this.hasCurrentOption && this.value) {
                this.getSelectedClient();
              }
            })
            .catch(error => {
              this.$captureError(error);
            })
            .finally(() => {
              this.loadingClients = false;
            });
        }
      }
    },
    getSelectedClient() {
      this.loadingClients = true;
      Clients.findById({
        id: this.value
      })
        .then(res => {
          this.clientsOptions.push({ value: res.id, text: res.name });
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => {
          this.loadingClients = false;
        });
    }
  }
};
</script>
