<template>
  <b-card>
    <form-header title="Generar factura" @back-action="$router.go(-1)" />
    <loading-spinner v-if="loading" />
    <b-row v-else>
      <b-col cols="12" md="6" lg="4">
        <issuer-form
          :data="issuerForm"
          :show-folio-input="true"
          class="h-100"
          @form-change="issuerForm = $event"
          @valid="isValidIssuerForm = $event"
        >
        </issuer-form>
      </b-col>
      <b-col cols="12" md="6" lg="8" class="mt-4 mt-md-0">
        <invoice-concepts
          :data="concepts"
          @concepts-change="conceptsChanged"
          @total-change="subtotal = $event"
          @concept-edited="conceptIsEditing"
        />
      </b-col>
      <b-col cols="12" class="mt-4">
        <receiver-form
          :data="receiverForm"
          @form-change="receiverForm = $event"
          @valid="isValidReceiverForm = $event"
          @billing-data-change="hasReceiverBillingDataChanges = $event"
        />
      </b-col>
    </b-row>
    <div class="pt-4">
      <z-button
        :disabled="isCreatingInvoice"
        class="mr-3"
        variant="secondary"
        @click="cancel"
      >
        Cancelar
      </z-button>
      <z-button
        :disabled="disableSaveButton"
        :loading="isCreatingInvoice"
        @click="save"
      >
        Facturar
      </z-button>
      <span
        v-show="hasReceiverBillingDataChanges"
        class="ml-3 text-secondary small"
      >
        Se creará/actualizará la información de facturación del usuario
        automaticamente
      </span>
    </div>
  </b-card>
</template>

<script>
import FormHeader from "@/app/components/FormHeader";
import InvoicePaymentMethod from "@/constants/invoices/payment-method";
import InvoicePaymentForm from "@/constants/invoices/payment-form";
import InvoiceUse from "@/constants/invoices/use";
import InvoiceConcepts from "./InvoiceConcepts";
import IssuerForm from "./IssuerForm";
import ReceiverForm from "./ReceiverForm";
import notifyMixin from "@/mixins/notify";
import BillingData from "@/services/billing-data";
import Invoices from "@/services/invoices";
import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";

export default {
  name: "OneToOneInvoice",

  components: {
    FormHeader,
    InvoiceConcepts,
    IssuerForm,
    ReceiverForm
  },

  mixins: [notifyMixin("notifications")],

  data() {
    return {
      loading: true,
      isValidIssuerForm: false,
      isValidReceiverForm: false,
      issuerForm: null,
      receiverForm: null,
      isCreatingInvoice: false,
      isCreatingBillingData: false,
      hasReceiverBillingDataChanges: false,
      concepts: [],
      subtotal: 0,
      isEditingConcepts: false
    };
  },

  computed: {
    billingAccounts() {
      return this.$store.state.invoices.billingAccounts.filter(
        ac => ac.billingData
      );
    },
    billingUsers() {
      return this.$store.state.invoices.billingUsers;
    },
    disableSaveButton() {
      return (
        !this.isValidIssuerForm ||
        !this.isValidReceiverForm ||
        this.isCreatingInvoice ||
        this.isCreatingBillingData ||
        this.isEditingConcepts
      );
    }
  },

  beforeMount() {
    this.getBillingUsers();
  },

  methods: {
    async getBillingUsers() {
      this.loading = true;
      await this.$store.dispatch("invoices/fetchAccounts");
      await this.$store.dispatch("invoices/fetchUsers");

      if (this.$route.params.id) {
        this.getInvoice(this.$route.params.id);
      } else {
        this.loading = false;
      }
    },
    getInvoice(id) {
      Invoices.find({ id })
        .then(res => {
          this.issuerForm = {
            account: this.billingAccounts.find(
              el => el.billingData.rfc === res.issuerRfc
            ),
            concept: res.concept,
            groupName: res.groupName,
            paymentMethod: InvoicePaymentMethod.options.find(
              el => el.value === res.paymentMethod
            ),
            paymentForm: InvoicePaymentForm.options.find(
              el => el.value === res.paymentForm
            ),
            cfdiUse: InvoiceUse.options.find(el => el.value === res.cfdiUse),
            folio: res.folio || null,
            createdAt: parseISO(res.createdAt)
          };
          let receiverUser = this.billingUsers.find(
            el => el?.billingData?.rfc === res.receiverRfc
          );

          if (!receiverUser) {
            receiverUser = this.billingAccounts.find(
              el => el?.billingData?.rfc === res.receiverRfc
            );
          }

          const { email, phone } = receiverUser;
          const {
            billingName,
            city,
            neighborhood,
            numExt,
            numInt,
            rfc,
            state,
            street,
            zipcode
          } = receiverUser.billingData;

          this.receiverForm = {
            user: receiverUser,
            billingName: billingName,
            rfc: rfc,
            email: email,
            phone: phone || receiverUser?.contactPhone || "",
            zipCode: zipcode,
            state: state,
            city: city,
            neighborhood: neighborhood,
            street: street,
            numInt: numInt,
            numExt: numExt
          };

          this.concepts = res.items;
        })
        .catch(err => {
          this.$captureError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    save() {
      this.generateInvoice();

      /** @todo create new user **/
      if (this.receiverForm.user?.id != null) {
        this.generateBillingData();
      }
    },

    generateInvoice() {
      this.isCreatingInvoice = true;
      Invoices.generate(this.makeRequestData())
        .then(res => {
          this.notify({
            title: "Factura creada",
            text: `Id de facturama : ${res.satWsId}`,
            type: "success"
          });
          this.$router.push({ name: "invoices" }).catch(() => {});
        })
        .catch(err => {
          console.log(err);
          this.notify({
            title: "Ocurrió un error al intentar crear la factura",
            text: err.name || "",
            type: "error"
          });
        })
        .finally(() => {
          this.isCreatingInvoice = false;
        });
    },

    generateBillingData() {
      const zipcode = this.receiverForm.zipCode;
      const data = {
        ...this.receiverForm,
        zipcode
      };
      const clientId = this.receiverForm.user.id;
      let hasBillingData = false;

      if (this.receiverForm.user.hasBillingData) {
        hasBillingData = true;
      }

      if (this.receiverForm.user.hasAccountBillingData) {
        data.accountsId = clientId;
      } else {
        data.clientId = clientId;
      }

      if (hasBillingData && this.hasReceiverBillingDataChanges) {
        this.updateBillingData(data, clientId);
      } else if (!hasBillingData) {
        this.createBillingData(data, clientId);
      }
    },

    createBillingData(data, clientId) {
      this.isCreatingBillingData = true;
      BillingData.createBillingData(data)
        .then(() => {
          this.notify({
            title: "Información de facturación creada",
            text: `Usuario con id ${clientId} tiene información de facturación`,
            type: "success"
          });
          this.$router.push({ name: "invoices" }).catch(() => {});
        })
        .catch(err => {
          console.log(err);
          this.notify({
            title:
              "Ocurrió un error al intentar crear la información de facturación",
            text: err.name || "",
            type: "error"
          });
        })
        .finally(() => {
          this.isCreatingBillingData = false;
        });
    },

    updateBillingData(data, clientId) {
      this.isCreatingBillingData = true;
      BillingData.updateBillingData(data)
        .then(() => {
          this.notify({
            title: "Información de facturación actualizada",
            text: `Usuario con id ${clientId} tiene información de facturación`,
            type: "success"
          });
          this.$router.push({ name: "invoices" }).catch(() => {});
        })
        .catch(err => {
          console.log(err);
          this.notify({
            title:
              "Ocurrió un error al intentar crear la información de facturación",
            text: err.name || "",
            type: "error"
          });
        })
        .finally(() => {
          this.isCreatingBillingData = false;
        });
    },

    makeRequestData() {
      const issuer = this.parseIssuer(this.issuerForm);
      const receiver = this.parseReceiver(this.receiverForm);

      const data = {
        issuer,
        receiver,
        cfdiUse: this.issuerForm.cfdiUse.value,
        paymentForm: this.issuerForm.paymentForm.value,
        paymentMethod: this.issuerForm.paymentMethod.value,
        concept: this.issuerForm.concept,
        zipCode: this.issuerForm.account.billingData.zipcode,
        amount: this.subtotal,
        type: 0,
        useAlternateEmails: this.receiverForm.useAlternateEmails,
        items: this.concepts,
        createdAt: formatISO(this.issuerForm.createdAt)
      };
      if (this.issuerForm.folio != null && this.issuerForm.folio !== "")
        data.folio = this.issuerForm.folio;

      return data;
    },

    parseIssuer(issuerForm) {
      let issuer = {};
      const { billingName, rfc, fiscalRegime } = issuerForm.account.billingData;
      issuer.name = billingName;
      issuer.rfc = rfc;
      issuer.fiscalRegime = fiscalRegime;
      return issuer;
    },

    parseReceiver(receiverForm) {
      let receiver = {};
      const { billingName, rfc } = receiverForm;
      receiver.name = billingName;
      receiver.rfc = rfc;
      return receiver;
    },

    cancel() {
      this.$router.push({ name: "invoices" }).catch(() => {});
    },

    conceptsChanged(concepts) {
      this.concepts = concepts;
      this.isEditingConcepts = this.concepts.some(c => c.isEditing);
    },

    conceptIsEditing({ index, isEditing }) {
      this.concepts[index].isEditing = isEditing;
      this.isEditingConcepts = this.concepts.some(c => c.isEditing);
    }
  }
};
</script>
