<template>
  <div class="issuer-form rounded border p-4 pb-5">
    <b-row>
      <b-col>
        <div class="h5 mb-4">
          {{ title }}
        </div>
        <b-form-group
          id="fieldset-created-at"
          class="mb-3"
          label="Fecha de creación"
          label-for="input-created-at"
          description="No debe de ser mayor a 72 horas para la emisión del timbre"
        >
          <z-date-picker
            id="input-created-at"
            v-model="issuerForm.createdAt"
            :disabled-dates="disabledDates"
            :format="dateFormatter"
            placeholder="Desde"
          />
        </b-form-group>
        <b-form-group
          v-if="showGroupName"
          id="fieldset-groupName"
          class="mb-4"
          label="Nombre"
          label-for="input-groupName"
        >
          <b-form-input
            id="input-groupName"
            v-model="issuerForm.groupName"
            name="groupName"
            placeholder="Asigna un nombre al grupo de facturas"
            trim
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="fieldset-account"
          class="mb-4"
          label="Cuenta"
          label-for="select-account"
        >
          <z-dropdown
            v-model="issuerForm.account"
            :options="billingAccounts"
            variant="white"
            placeholder="Selecciona la cuenta"
            :show-header="false"
            search
          />
          <span v-show="satWSRegistered === false" class="error-validation"
            >El RFC de la cuenta no está registrado en el SAT</span
          >
        </b-form-group>
        <b-form-group
          id="fieldset-cfdi-use"
          class="mb-4"
          label="Uso del CFDI"
          label-for="select-cfdi-use"
        >
          <z-dropdown
            v-model="issuerForm.cfdiUse"
            :options="cfdiUseOptions"
            variant="white"
            :show-header="false"
            search
          />
        </b-form-group>
        <b-form-group
          id="fieldset-payment-type"
          class="mb-4"
          label="Forma de pago"
          label-for="select-payment-type"
        >
          <z-dropdown
            v-model="issuerForm.paymentForm"
            :options="paymentFormOptions"
            variant="white"
            :show-header="false"
            search
          />
        </b-form-group>
        <b-form-group
          id="fieldset-payment-method"
          class="mb-4"
          label="Método de pago"
          label-for="select-payment-method"
        >
          <z-dropdown
            v-model="issuerForm.paymentMethod"
            :options="paymentMethodOptions"
            variant="white"
            :show-header="false"
            search
          />
        </b-form-group>
        <b-form-group
          v-if="showFolioInput"
          id="fieldset-folio-number"
          class="mb-4"
          label="Número de folio"
          label-for="input-folio-number"
          :state="veeErrors.first('folio') ? false : true"
          :invalid-feedback="veeErrors.first('folio')"
        >
          <b-form-input
            id="input-folio-number"
            v-model="issuerForm.folio"
            v-validate="'max:15'"
            placeholder="p. ej. MX-20-W38"
            name="folio"
            data-vv-as="Folio"
            trim
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ZDatePicker from "@zubut/common/src/components/ZDatePicker";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import InvoicePaymentMethod from "@/constants/invoices/payment-method";
import InvoicePaymentForm from "@/constants/invoices/payment-form";
import InvoiceUse from "@/constants/invoices/use";
import validateStateMixin from "@/mixins/validateState";
import { format } from "@zubut/common/src/utils/time";
import dateSub from "date-fns/sub";
import endOfDay from "date-fns/endOfDay";
import _cloneDeep from "lodash/cloneDeep";

export default {
  name: "IssuerForm",

  components: {
    ZDatePicker,
    ZDropdown
  },

  mixins: [validateStateMixin],

  props: {
    data: {
      type: Object,
      default: null
    },
    showGroupName: {
      type: Boolean,
      default: false
    },
    showFolioInput: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Emisor"
    }
  },

  data() {
    return {
      cfdiUseOptions: InvoiceUse.options,
      paymentFormOptions: InvoicePaymentForm.options,
      paymentMethodOptions: InvoicePaymentMethod.options,
      issuerForm: {
        concept: "Servicio de mensajería",
        groupName: null,
        account: null,
        folio: null,
        cfdiUse: InvoiceUse.GENERAL_EXPENSE_KEY,
        paymentForm: InvoicePaymentForm.TO_DEFINE_KEY,
        paymentMethod: InvoicePaymentMethod.ONE_TIME_PAYMENT_KEY,
        createdAt: Date.now()
      },
      satWSRegistered: null,
      disabledDates: {
        to: dateSub(Date.now(), { days: 4 }),
        from: endOfDay(Date.now())
      }
    };
  },

  computed: {
    isValidIssuerForm() {
      return (
        this.isFormValid && this.isValidIssuerAccount // && this.satWSRegistered
      );
    },
    isValidIssuerAccount() {
      return this.issuerForm.account != null;
    },
    billingAccounts() {
      return this.$store.state.invoices.billingAccounts
        .filter(ac => ac.billingData)
        .map(el => ({ value: el.id, text: el.name, ...el }));
    },
    selectedAccount() {
      return this.billingAccounts.find(el => el.id === this.issuerForm.account);
    }
  },

  watch: {
    isValidIssuerForm(newVal) {
      this.$emit("valid", newVal);
    },

    issuerForm: {
      deep: true,
      handler() {
        const form = _cloneDeep({
          ...this.issuerForm,
          account: this.selectedAccount
        });
        this.$emit("form-change", form);
      }
    },

    "issuerForm.account"() {
      const rfc = this.selectedAccount?.billingData?.rfc;
      if (rfc) {
        this.setSatWSRegistrationStatus(rfc);
      }
    },

    "issuerForm.createdAt"(newVal, oldVal) {
      const newDate = newVal?.getTime();
      const oldDate = oldVal?.getTime ? oldVal.getTime() : oldVal;
      const minDate = dateSub(Date.now(), { days: 2, hours: 23, minutes: 30 });
      if (newDate !== oldDate && newDate < minDate) {
        this.issuerForm.createdAt = minDate;
      }
    }
  },

  mounted() {
    if (this.data) {
      this.issuerForm.account = this.data.account;
      this.issuerForm.concept = this.data.concept;
      this.issuerForm.groupName = this.data.groupName;
      this.issuerForm.account = this.data.account;
      this.issuerForm.folio = this.data.folio;
      this.issuerForm.cfdiUse = this.data.cfdiUse;
      this.issuerForm.paymentForm = this.data.paymentForm;
      this.issuerForm.paymentMethod = this.data.paymentMethod;
      this.issuerForm.createdAt = this.data.createdAt;
    }
  },

  methods: {
    clearIssuerFormFields() {
      this.issuerForm.concept = "";
      this.issuerForm.groupName = "";
      this.issuerForm.account = null;
      this.issuerForm.folio = null;
      this.issuerForm.cfdiUse = null;
      this.issuerForm.paymentForm = null;
      this.issuerForm.paymentMethod = null;
      this.issuerForm.createdAt = Date.now();
    },

    clearForm() {
      this.clearIssuerFormFields();
    },

    setSatWSRegistrationStatus(rfc) {
      this.satWSRegistered = null;
      this.$store
        .dispatch("invoices/satWSRegistrationStatus", rfc)
        .then(status => {
          this.satWSRegistered = status.exists;
        })
        .catch(err => {
          this.satWSRegistered = false;
        });
    },

    dateFormatter(date) {
      return format(date, "MMMM dd yyyy, h:mm a");
    }
  }
};
</script>
