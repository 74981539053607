// Keys
const SMALL_PACKAGE_DELIVERY_KEY = "78102205";
const GENERAL_GOODS_STORAGE_KEY = "78131600";
const APPLICATION_DEVELOPMENT_KEY = "81111504";

// Key name
const SMALL_PACKAGE_DELIVERY = "Entrega de paquetes pequeños";
const GENERAL_GOODS_STORAGE = "Almacenamiento de bienes generales";
const APPLICATION_DEVELOPMENT = "Servicio de programación de aplicaciones";

const get = {};
get[SMALL_PACKAGE_DELIVERY_KEY] = SMALL_PACKAGE_DELIVERY;
get[GENERAL_GOODS_STORAGE_KEY] = GENERAL_GOODS_STORAGE;
get[APPLICATION_DEVELOPMENT_KEY] = APPLICATION_DEVELOPMENT;

const options = [
  {
    value: SMALL_PACKAGE_DELIVERY_KEY,
    text: `${SMALL_PACKAGE_DELIVERY_KEY} - ${SMALL_PACKAGE_DELIVERY}`
  },
  {
    value: GENERAL_GOODS_STORAGE_KEY,
    text: `${GENERAL_GOODS_STORAGE_KEY} - ${GENERAL_GOODS_STORAGE}`
  },
  {
    value: APPLICATION_DEVELOPMENT_KEY,
    text: `${APPLICATION_DEVELOPMENT_KEY} - ${APPLICATION_DEVELOPMENT}`
  }
];

export default {
  get,
  options,
  SMALL_PACKAGE_DELIVERY_KEY,
  SMALL_PACKAGE_DELIVERY
};
