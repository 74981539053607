<template>
  <div>
    <div class="header">
      <div class="back-arrow d-inline mt-1" @click.stop="$emit('back-action')">
        <arrow-left-icon />
      </div>
      <div class="header-info">
        <p id="title" class="text title">{{ title }}</p>
        <div class="text action" @click.stop="$emit('back-action')">
          Regresar
        </div>
      </div>
    </div>
    <div class="mt-3 mb-3 divider" />
  </div>
</template>

<script>
import ArrowLeftIcon from "@/assets/icons/ic_arrow_left.svg";

export default {
  name: "FormHeader",
  components: {
    ArrowLeftIcon
  },
  props: {
    title: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.divider {
  height: 1px;
  background-color: $gainsboro;
}

.text {
  color: $nero;
  font-size: 12px;

  &.title {
    font-size: 20px;
    font-weight: 600;
  }

  &.action {
    color: $dodger-blue;
    font-size: 12px;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: flex;
  font-size: 1.5em;
  flex: 1;
  margin: 1em 0em;

  .back-arrow {
    cursor: pointer;
    svg {
      fill: $dodger-blue;
    }
  }

  .header-info {
    padding: 0 0.5em;

    #title {
      margin: 4px 0px;
    }
  }
}
</style>
